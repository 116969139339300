@use "@/styles/constants/devices.scss" as *;

.cardContainer {
  position: relative;
  list-style: none;
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid var(--darkerBorder);
  border-radius: 4px;
  padding: 6px;

  &--sm {
    @extend .cardContainer;
    width: calc(50% - 8px);

    @media screen and (min-width: $laptop) {
      width: calc(33% - 8px);
    }
  }

  &:hover {
    box-shadow: var(--card-contentHover) 0 0 8px;
  }
}

.cardLink {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  &--md {
    @extend .cardLink;
    > span {
      min-width: 103px;
      width: 103px;
      min-height: 130px;
      max-height: 130px;
    }
  }

  &--lg {
    @extend .cardLink;

    @media screen and (min-width: $tablet) {
      > span {
        min-width: 165px;
        min-height: 209px;
      }
    }

    @media screen and (min-width: $laptop) {
      flex-direction: row;
    }
  }
}

.cardImageParent {
  position: relative;
  overflow: hidden;
  width: 100%;
  &--sm {
    @extend .cardImageParent;
    height: 95px;
  }

  &--md {
    @extend .cardImageParent;
    height: 130px;
  }

  &--lg {
    @extend .cardImageParent;
    height: 158px;

    @media only screen and (min-width: $laptop) {
      width: 280px;
    }
  }
}

.cardImage {
  border-radius: 4px;
  object-fit: cover;

  &--lg {
    @extend .cardImage;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
  flex: 1;
  color: var(--defaultText);

  &--lg {
    @extend .cardBody;
    padding: 16px 16px 48px; // need to add space for the CardMeta component to be absolutely positioned
  }
}

.cardHeader {
  line-height: 1.05rem;
  font-family: var(--font-montserrat);
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 12px;

  @media only screen and (min-width: $tablet) {
    font-size: 0.875rem;
  }

  &--sm {
    @extend .cardHeader;
    font-size: 0.625rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @media only screen and (min-width: $tablet) {
      font-size: 0.75rem;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
    }
  }

  &--md {
    @extend .cardHeader;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @media only screen and (min-width: $tablet) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.cardMeta {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0;
  font-size: 0.625rem;
  position: absolute;
  bottom: 6px;
  width: calc(100% - 14px);
  box-sizing: border-box;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--lg {
    @extend .cardMeta;
    padding: 16px 16px 0;
    width: 100%;
    left: 0;
    right: 0;

    @media only screen and (min-width: $laptop) {
      width: calc(100% - 286px); // 280px image width with 6px padding
      left: auto;
    }
  }
}

.cardExtract {
  font-size: 0.625rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  &--md {
    @extend .cardExtract;
    font-size: 0.75rem;
    @media screen and (min-width: $tablet) {
      font-size: 0.8125rem;
    }
  }

  &--lg {
    @extend .cardExtract;
    display: none;

    @media screen and (min-width: $tablet) {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
    }
  }
}

.cardCategory {
  color: var(--defaultText);
  text-transform: capitalize;
  &:not(span) {
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
